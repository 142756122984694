import { Image as ImageLayer } from 'ol/layer';
import { ImageArcGISRest } from 'ol/source';
import { Map } from 'ol';

import * as interaction from 'ol/interaction';
import PinchZoom from 'ol/interaction/PinchZoom';

import { MapCore, ESRI_URL } from '@core/js/mapCore';
import { htmlToElement } from '@core/js/map-helpers.js';

export const FORECAST_TYPES = {
  static: 'static',
  imageLayer: 'imageLayer'
}

const MAP_TARGET = 'weather-map';
const MAP_LABEL= 'Weather Map';

window.tg = window.tg || {};
window.tg.weatherMap = window.tg.weatherMap || {
  url: 'https://maps3dev.timmons.com/arcgis/rest/services/ne_wrap_weather_np/ne_wrap_weather_np'
};

export default class WeatherMap extends MapCore {
  constructor(props) {
    super();
    this.weatherConfig = window.tg.weatherMap;

    this.initProperties();

    let requestAnimationFrame = this.getRequestAnimationFrame();
    requestAnimationFrame(() => {
      if (document.getElementById(this.mapTarget) === null) {
        throw new Error(
          `Map target ${this.mapTarget} cannot be found. Render failed...`
        );
      }
      this.setDomElements();
      this.initMap();
    });
  }

  initProperties() {
    super.initProperties({
      baseMapType: this.weatherConfig.baseType,
      mapTarget: MAP_TARGET,
      mapLabel: MAP_LABEL
    });

    this.weatherUrl = `${this.weatherConfig.url}/MapServer`;
    this.forecastsArea = null;
    this.forecastImage = null;
    this.forecastMap = null;
    this.activeCategory = -1;
    this.activeForecast = null;
    this.activeForecastLayer = null;
    this.categoryButton = null;
    this.forecastButton = null;
    this.forecasts = {};
    this.legendUrls = [];
    this.legends = {};
    this.defaultExtent = null;
  }

  setDomElements() {
    this.forecastMap = document.getElementById(MAP_TARGET);
    this.forecastsArea = document.getElementById('forecasts-area');
    this.forecastImage = document.getElementById('weather-map-image');
    this.legendContainer = document.getElementById('weather-map-legend-container');
    this.legendArea = document.getElementById('weather-legend');
    this.legendButton = document.getElementById('weather-legend-button');

    this.legendButton.addEventListener('click', () => {
      const action = this.legendArea.classList.contains('active') ? 'remove' : 'add';
      this.legendArea.classList[action]('active');
    });
  }

  /**
   * Click handler for category button
   * @param {Event} e
   */
  categoryButtonClick(e) {
    this.changeActiveCategory(e.target);
  }

  /**
   * Change the forecast category and show its forecast options and auto selecting the first option
   * @param {Element} button category button to use for the change
   */
  changeActiveCategory(button) {
    if (!button) {
      return;
    }

    const cIndex = parseInt(button.getAttribute('data-index'));
    if (cIndex === this.activeCategory) {
      return;
    }

    if (this.categoryButton) {
      this.categoryButton.classList.remove('active');
    }

    this.setForecastAreaActiveState(this.activeCategory, false);

    button.classList.add('active');
    this.categoryButton = button;

    const forecastArea = this.setForecastAreaActiveState(cIndex, true);
    if (forecastArea) {
      const foreName = this.getForecastName(forecastArea.querySelector('.forecast-layer-button'));
      this.changeActiveForecast(foreName);
    }

    this.activeCategory = cIndex;
  }

  /**
   * Derive a forecast name from category and forecast ids
   * @param {Element} button
   * @returns {string}
   */
  getForecastName(button) {
    const catId = button.getAttribute('data-categoryid');
    const fId = button.getAttribute('data-forecastid');
    return `${catId}-${fId}`;
  }

  /**
   * Get and set the active state of forecast area based on an index
   * @param {number} index category forecast area to apply state change to
   * @param {boolean} active add 'active' class if true, remove if false
   * @returns {Element} forecast area
   */
  setForecastAreaActiveState(index, active) {
    const area = this.getForecastButtonArea(index);
    if (area) {
      area.classList[active ? 'add' : 'remove']('active');
    }

    return area;
  }

  /**
   * Return the forecast option area for a give category index
   * @param {number} index category index
   * @returns {Element} forecast area Element for a given category index
   */
  getForecastButtonArea(index) {
    return this.forecastsArea ? this.forecastsArea.querySelector(`#forecast-${index}-options`) : null;
  }

  /**
   * Create the Openlayers map instance
   *
   * Setup / add any addition layers
   */
  initMap() {
    let layers = [this.baseMapLayer()];

    if (!window.tg.weatherMap) {
      this.map = this.failSafeMap(layers);
      return;
    }

    this.map = new Map({
      layers,
      target: this.mapTarget,
      loadTilesWhileAnimating: true,
      interactions: interaction.defaults({}).extend([
        new PinchZoom({
          constrainResolution: true
        })
      ]),
      view: this.mapViewObject({})
    });

    this.setupForecasts();

    const street = new ImageLayer({
      source: new ImageArcGISRest({ url: `${ESRI_URL}/Reference/World_Transportation/MapServer` })
    });
    this.map.addLayer(street);

    //
    if (this.legendUrls.length) {
      fetch(`${this.legendUrls[0].url}?f=json`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.defaultExtent = this.transformMapServerExtent(data.fullExtent);
          this.changeExtentControl();
          this.fitMap(this.defaultExtent);
          this.setCanvasLabel()
        }).catch((e) => { });
    }
  }

  /**
   * Create forecast layers / images based on data attributes found on forecast buttons
   */
  setupForecasts() {
    const mapLayers = [];
    const forecastButtons = document.querySelectorAll('.forecast-layer-button');
    forecastButtons.forEach((el, index) => {
      const forecast = this.createForecast(el);
      if (forecast.type !== FORECAST_TYPES.static) {
        mapLayers.push(forecast.layer);
      }
    });

    if (mapLayers.length) {
      this.map.getLayers().extend(mapLayers);
    }

    const categories = document.querySelectorAll('.weather-category-button');
    categories.forEach((el, index) => {
      if (!index) {
        this.changeActiveCategory(el);
      }
      el.addEventListener('click', this.categoryButtonClick.bind(this));
    });
  }

  createForecast(button) {
    const forecastName = this.getForecastName(button);
    const layerUrl = button.getAttribute('data-url');

    if (!this.forecasts.hasOwnProperty(forecastName)) {
      this.forecasts[forecastName] = {
        element: button,
        name: forecastName,
        id: parseInt(button.getAttribute('data-forecastid')),
        categoryId: parseInt(button.getAttribute('data-categoryid')),
        layerIndex: null,
        type: null,
        extent: null,
        url: null,
        layer: null,
        legend: null
      };
    }

    const forecast = this.forecasts[forecastName];

    if (layerUrl) {
      forecast.layerIndex = parseInt(button.getAttribute('data-lid'));
      forecast.url = layerUrl;
      const fLayer = this.createImageLayer(layerUrl, forecast.layerIndex, forecastName);
      fLayer.setVisible(false);

      forecast.layer = fLayer;
      forecast.type = FORECAST_TYPES.imageLayer;

      this.initLegend(forecast);
      // Preload the extent for the first layer of each category
      if (forecast.id === 0) {
        this.getForecastExtent(forecast);
      }
    } else {
      const imageUrl = button.getAttribute('data-imageurl');
      if (imageUrl) {
        forecast.type = FORECAST_TYPES.static;
        forecast.url = imageUrl;
      }
    }

    button.addEventListener('click', () => {
      this.changeActiveForecast(forecastName);
    });

    return forecast;
  }

  /**
   *
   * @param {string} forecastName
   * @param {string} url
   */
  initLegend(forecast) {
    if (!forecast || forecast.type === FORECAST_TYPES.static) {
      return;
    }

    if (!forecast.legend) {
      forecast.legend = {
        index: -1,
        element: null
      };
    }
    const url = forecast.url;
    let legendUrlItem = this.getLegendByUrl(url);
    if (!legendUrlItem) {
      this.legendUrls.push({
        url,
        forecasts: [forecast.name],
        loaded: false,
        data: null
      });
    } else {
      legendUrlItem.forecasts.push(forecast.name);
    }

    forecast.legend.index = this.getLegendIndexByUrl(url);
  }

  legendIsLoaded(url) {
    const legend = this.getLegendByUrl(url);
    return legend ? legend.loaded : false;
  }

  getLegendByUrl(url) {
    return this.legendUrls.find(entry => entry.url === url);
  }

  getLegendIndexByUrl(url) {
    return this.legendUrls.findIndex(entry => entry.url === url);
  }

  /**
   * Attempt to load legend for a given MapserverUrl
   * @param {string} url Base MapServer url
   */
  showLegend(forecastName) {
    const forecast = this.forecasts[forecastName];
    const legend = forecast.legend;
    if (!legend) {
      return;
    }

    this.legendArea.innerHTML = '';

    //attach legend to the legend area
    // If element does not exist check if legend url has already been loaded
    // If loaded, render if not load
    if (legend.element) {
      this.legendArea.appendChild(legend.element);
    } else if (legend.index >= 0) {
      const legendUrlItem = this.legendUrls[legend.index];
      if (!legendUrlItem.loaded) {
        this.loadLegend(forecast, legendUrlItem);
      } else {
        this.renderLegend(forecast, legendUrlItem);
      }
    }
  }

  getForecastExtent(forecast, applyOnLoad) {
    fetch(`${forecast.url}/${forecast.layerIndex}?f=json`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        forecast.extent = this.transformMapServerExtent(data.extent);
        if (applyOnLoad) {
          this.changeExtentControl();
          this.fitMap(forecast.extent);
        }
      }).catch((e) => { });
  }

  loadLegend(forecast, legendUrlItem) {
    if (!legendUrlItem || legendUrlItem.loaded) {
      return;
    }

    fetch(`${legendUrlItem.url}/Legend?f=json`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        legendUrlItem.data = data;
        legendUrlItem.loaded = true;
        this.renderLegend(forecast, legendUrlItem)
      }).catch((e) => { });
  }

  renderLegend(forecast, legendUrlItem) {
    if (!legendUrlItem || !legendUrlItem.data) {
      return;
    }
    const layerIndex = parseInt(forecast.layerIndex);
    const layer = legendUrlItem.data.layers.find(layer => layer.layerId === layerIndex)
    const lines = [];
    if (layer && layer.legend.length) {
      layer.legend.forEach(line => {
        const item = `<li class="legend-item">
          <img class="legend-image" src="${legendUrlItem.url}/${layerIndex}/images/${line.url}" alt="legend image ${layerIndex}"></img>
          <span class="legend-label">${line.label}</span>
        </li>`;

        lines.push(item);
      });
    } else {
      lines.push('<li class="legend-item">No legend found for this layer</li>')
    }

    forecast.legend.element = htmlToElement(`<ul class="weather-legend-list">${lines.join('')}</ul>`);
    this.legendArea.appendChild(forecast.legend.element);
  }

  /**
   * Create a new ImageLayer with an ImageArcGISRest source
   * @param {string} url Mapserver base url (should end in MapServer)
   * @param {number} id Index / id of the layer to show
   * @param {string} name Name of the layer should be unique
   * @returns {ImageLayer} Newly created ImageLayer instance
   */
  createImageLayer(url, id, name) {
    return new ImageLayer({
      name,
      source: new ImageArcGISRest({
        url,
        ratio: 1,
        params: {
          LAYERS: `show:${id}`
        },
      }),
    })
  }

  /**
   * Change the active forecast map layer or image
   * @param {Element} button Forecast button requesting change
   */
  changeActiveForecast(foreName) {
    // Check for layername to determine if image or map layer
    const forecast = this.forecasts[foreName];
    const button = forecast.element;

    this.activeForecast = foreName;
    if (forecast.type === FORECAST_TYPES.static) {
      if (forecast.url) {
        this.setMapActiveState();
        this.forecastImage.setAttribute('src', forecast.url);
      }
      this.legendContainer.classList.add('hidden');
    } else {
      const layer = forecast.layer;
      if (layer != this.activeForecastLayer && this.activeForecastLayer) {
        this.activeForecastLayer.setVisible(false);
      }

      if (!forecast.extent) {
        this.getForecastExtent(forecast, true);
      } else {
        this.changeExtentControl();
        this.fitMap(forecast.extent);
      }
      layer.setVisible(true);
      this.showLegend(foreName);
      this.legendContainer.classList.remove('hidden');
      this.activeForecastLayer = layer;
      this.setMapActiveState(true);
    }

    if (this.forecastButton) {
      this.forecastButton.classList.remove('active');
    }

    button.classList.add('active');
    this.forecastButton = button;
  }

  /**
   * Toggle whether the map or the image area is active
   * @param {boolean} active If true will show the map and hide the image
   */
  setMapActiveState(active = false) {
    const action = active ? 'remove' : 'add';
    this.forecastMap.classList[action]('hidden');
    this.forecastImage.classList[action]('active');
  }

  /**
   * Get the current extent
   *
   * If no active forecast extent then use default
   * @returns {ol.extent}
   */
  get currentExtent() {
    const forecast = this.forecasts[this.activeForecast];
    return forecast && forecast.extent ? forecast.extent : this.defaultExtent;
  }

  /**
   * Destroy previous extent control and create with new extent.
   */
  changeExtentControl() {
    if (this.extentControl) {
      this.map.removeControl(this.extentControl);
      this.extentControl = null;
    }

    this.extentControl = this.zoomToExtentControl(this.currentExtent);
    this.map.addControl(this.extentControl);
  }
}