import RiskMap from '@core/js/riskMap';
import ContactForm from '@core/js/contactForm';
import WeatherMap from './weatherMap';
class Landing {
  constructor() {
    new RiskMap();
    new WeatherMap();
    new ContactForm();
  }
}

new Landing();